// Login from cart drawer

document.addEventListener('DOMContentLoaded', () => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const openCartDrawer = params.get('open-cart-drawer')
  const cartDrawer = document.getElementById('cart-icon-bubble')

  if (openCartDrawer === 'true') {
    cartDrawer.dispatchEvent(new Event('click'))
    params.delete('open-cart-drawer')
    const newUrl = `${url.pathname}${
      params.toString() ? '?' + params.toString() : ''
    }`
    history.replaceState({}, '', newUrl)
  }
})
